import dynamic from 'next/dynamic';
import { Box, Button, ButtonProps } from '@chakra-ui/react';
import {
  AcfButtonLink,
  MhyCtaButton,
  MhyChakraProps,
  MhyFormButton,
} from '../types';

const ButtonLink = dynamic(() => import('./ButtonLink'));
const FormModalButton = dynamic(() => import('./FormModalButton'));

export interface ButtonsProps extends Pick<ButtonProps, 'size'> {
  buttons: (AcfButtonLink | MhyCtaButton | MhyFormButton | ButtonProps)[];
  inverted?: true;
}

const isMhyFormButton = (
  button: ButtonsProps['buttons'][number],
): button is MhyFormButton => 'form' in button;

/** Primary button (first) is by default rendered as "solid green"
 *  and the following are rendered as "outline white"
 *  UNLESS you override them with variant prop  */
const Buttons = ({
  buttons,
  size,
  inverted,
  ...rest
}: ButtonsProps & Omit<MhyChakraProps, 'variant'>) => {
  const altColor = inverted ? 'outlineWhite' : 'outlineGreen';
  return (
    <Box display="flex" gridGap={3} flexWrap="wrap" {...rest}>
      {buttons
        ?.filter(
          (btn) =>
            !!btn &&
            !!(
              btn?.children ||
              btn?.title ||
              (btn as AcfButtonLink).link?.title
            ),
        )
        .map(({ variant, ...buttonProps }, i) => {
          if ((buttonProps as AcfButtonLink).link?.url) {
            const { link, ...restButtonProps } = buttonProps as AcfButtonLink;
            return (
              <ButtonLink
                key={`${
                  buttonProps?.id ||
                  link.url ||
                  buttonProps.children ||
                  link?.title
                }-${i}`}
                link={link}
                variant={variant || (!i ? 'solidGreen' : altColor)}
                size={size}
                {...restButtonProps}
              />
            );
          }
          if (
            isMhyFormButton(buttonProps) &&
            typeof buttonProps.form !== 'string'
          ) {
            return (
              <FormModalButton
                key={`${buttonProps?.id || buttonProps.children}-${i}`}
                variant={variant || (!i ? 'solidGreen' : altColor)}
                size={size}
                data-test-id={`form-modal-button-${i + 1}`}
                {...buttonProps}
              >
                {buttonProps.children || buttonProps.title}
              </FormModalButton>
            );
          }
          return (
            <Button
              key={`${buttonProps?.id || buttonProps.children}-${i}`}
              variant={variant || (!i ? 'solidGreen' : altColor)}
              size={size}
              {...(buttonProps as ButtonProps)}
            >
              {buttonProps.children || buttonProps.title}
            </Button>
          );
        })}
    </Box>
  );
};
export default Buttons;
