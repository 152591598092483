import dynamic from 'next/dynamic';
import { ElementType, FC } from 'react';
import {
  Box,
  Grid,
  GridProps,
  Text,
  useStyleConfig,
  ButtonProps,
} from '@chakra-ui/react';
import Buttons from './Buttons';
import HtmlContent from './HtmlContent';
import Image from './Image';
import {
  AcfHeading,
  AcfButtonLink,
  MhyChakraProps,
  NewsTeaser,
  MhyImage,
} from '../types';
import Heading from './Heading';

const ArticleInfo = dynamic(() => import('./ArticleInfo'));

export interface TeaserProps extends MhyChakraProps {
  date?: string;
  text?: string;
  wysiwyg?: string;
  icon?: string | null;
  image?: MhyImage;
  buttons?: (ButtonProps | AcfButtonLink)[] | false;
  heading: AcfHeading;
  textStyle?: string;
  site?: string;
  invertedColors?: true;
  author?: NewsTeaser['author'];
}

const Teaser: FC<TeaserProps & { innerProps?: GridProps }> = ({
  variant,
  date,
  text,
  wysiwyg,
  heading: { title, importance, style: headingStyle },
  buttons,
  icon,
  image,
  textStyle,
  site,
  children = null,
  invertedColors,
  alignItems = 'flex-start',
  gridRowGap = 5,
  innerProps = null,
  author,
  ...rest
}) => {
  const styles = useStyleConfig('Teaser', { variant });

  return (
    <Box __css={styles} {...rest}>
      {icon && (
        <Image
          src={icon}
          alt={title}
          width="88"
          height="88"
          role="presentation"
          containerProps={{
            width: ['70px', null, '88px'],
            height: ['70px', null, '88px'],
            m: '0 auto',
          }}
        />
      )}
      {image?.src && (
        <Image
          width="640px"
          height="432px"
          src={image.src}
          alt={image?.alt || title}
        />
      )}
      <Grid
        alignItems={alignItems}
        gridRowGap={gridRowGap}
        gridTemplateColumns="100%"
        py={3}
        {...innerProps}
      >
        {title && (
          <Heading mt={0} as={importance as ElementType} variant={headingStyle}>
            {title}
          </Heading>
        )}
        {(date || site || author) && (
          <ArticleInfo {...{ date, site, author }} mt={-2} />
        )}
        {text && (
          <Text textStyle={textStyle || 'sm'} noOfLines={4} maxWidth="65ch">
            {text}
          </Text>
        )}
        {
          wysiwyg && (
            <HtmlContent html={wysiwyg} />
          ) /* Wysiwyg editor outputs string without wrapping p */
        }
        {buttons && <Buttons buttons={buttons} inverted={invertedColors} />}
        {children}
      </Grid>
    </Box>
  );
};

export default Teaser;
